<template>

  <div class="flex flex-col w-full">

    <div class="flex flex-col items-center">
      <div class="text-4xl text-pt-gray uppercase text-center font-bold mt-8 w-full">
        Why print / prototype
      </div>

      <div class="p-4">
        <img class="slide-wrapper" :src="slides[slideIndex]" alt="">
      </div>
    </div>

    <div class="flex w-full justify-center mt-12">
      <div class="flex gap-10 w-8/12">
        <div class="w-7/12">
          <p class="mb-8">
            7 Billion people on this earth and everyone is different. Imagine having to wear a shoe size that does not really fit you. Initially, people had to make custom shoes which were expensive. Industrial revolution made it easy for everyone to afford a pair of shoes. But it came with a flaw - Mass produced products are not for everyone. With 3D printing, you should be able to print shoes to a perfect fit. Imagine downloading the design for a shoe and customising it in your home for the perfect fit. 3D printing opens up new possibilities which were unimaginable before.
          </p>

          <p class="mb-8">
            3D printing decentralises manufacturing in a huge way. Goods today are produced in factories, then via a supply chain it reachers the stores in a few places. These stores are definitely not accessible by everyone on the face of Earth. But with 3D printing, the manufacturing is localised. Anyone with with the access to the internet and a 3D printer can print things.
          </p>

          <p class="mb-8">
            Almost anything can be made using 3D printers and it has been the fastest growing technology of this decade. The quality of prints and the materials we can print on has gone up exponentially. The price has truly come down over the past two years and is set to come down a lot more.
          </p>
        </div>
        <div class="flex flex-col items-center w-5/12 text-center">
          <iframe
            width="420"
            height="315"
            src="https://www.youtube.com/embed/mX6G-TluQHE?rel=0&amp;controls=0&amp;showinfo=0"
            frameborder="0"
            allowfullscreen="">
          </iframe>
          <div class="uppercase text-pt-gray text-xl font-bold mt-4">
            How 3D Printing Works
          </div>
        </div>
      </div>

    </div>


    <div class="flex justify-center text-2xl uppercase mt-4 text-pt-gray font-bold">
      Our Works
    </div>

    <div class="flex gap-5 justify-center mt-4 mb-12">
      <div class="w-4/12 flex justify-center">
        <img class="object-cover h-64" src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/portfolio_1.jpg" alt="">
      </div>
      <div class="w-4/12 flex justify-center">
        <img class="object-cover h-64" src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/portfolio_2.jpg" alt="">
      </div>
      <div class="w-4/12 flex justify-center">
        <img class="object-cover h-64" src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/portfolio_3.jpg" alt="">
      </div>
    </div>

    <div class="flex justify-center mb-24">
      <router-link
        to="/portfolio"
        class="p-2 mx-2 uppercase text-md text-white bg-pt-red"
        >
        More in portfolio
      </router-link>
    </div>

  </div>

</template>

<script>

  export default {
    mounted() {
      var that = this;
      this.slideTimer = setInterval(function() {
        that.changeSlide();
      }, 5000);
    },
    data() {
      return {
        slideTimer: null,
        slideIndex: 0,
        slides: [
          "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/3d_1.jpg",
          "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/3d_2.jpg",
          "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/3d_3.jpg",
          "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/3d_4.jpg",
          "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/3d_5.jpg",
          "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/3d_6.jpg",
          "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/3d_7.jpg",
        ]
      }
    },
    components: {

    },
    methods: {
      changeSlide() {
        var that = this;
        this.animateCSS('.slide-wrapper', 'fadeOut').then(() => {
          if(that.slideIndex == 6) {
            that.slideIndex = 1;
          } else {
            that.slideIndex += 1;
          }
          that.animateCSS('.slide-wrapper', 'fadeIn');
        });
      },
      animateCSS(element, animation, prefix = 'animate__') {
        // We create a Promise and return it
        return new Promise((resolve, reject) => {
          const animationName = `${prefix}${animation}`;
          const node = document.querySelector(element);

          node.classList.add(`${prefix}animated`, animationName);

          // When the animation ends, we clean the classes and resolve the Promise
          function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
          }

          node.addEventListener('animationend', handleAnimationEnd, {once: true});
        });
      }
    },
    unmounted() {
      clearInterval(this.slideTimer);
    }
  }
</script>